<template>
  <div>
    <template v-for="(item, index) in options">
      <template v-if="values.includes(item.type)">
        <span
          :index="index"
        >
          {{ item.name }}
        </span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CustomDictTag',
  props: {
    options: {
      type: Array,
      default: null
    },
    value: {
      type: [Number, String, Boolean, Array],
      default: null
    }
  },
  computed: {
    values () {
      if (this.value !== null && typeof this.value !== 'undefined') {
        return Array.isArray(this.value) ? this.value : [this.value]
      } else {
        return []
      }
    }
  }
}
</script>
